import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BankInfo from '../components/BankInfo';

const Account = () => {
  const stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_PK_KEY}`);

  return (
    <div>
      <h1>account page</h1>

      <div>
        ACH / DEBIT CARD SECTION By registering your account, you agree to our Services Agreement
        and the{' '}
        <a href="https://stripe.com/connect-account/legal">Stripe Connected Account Agreement </a>.
      </div>
      <Elements stripe={stripePromise}>
        <BankInfo />
      </Elements>
    </div>
  );
};

export default Account;
